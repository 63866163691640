@import 'tailwindcss/components';
//@import 'recipes';
@import 'content-quote';
@import "mmenu-js/dist/mmenu.css";
@import '../../node_modules/@splidejs/splide/dist/css/splide.min.css';
@import 'splide';
.t-bg-leaves {
  background-color: #fff;
  background-image: url(../../assets/img/Leaves-01.png),url(../../assets/img/Seeds-02.png),url(../../assets/img/Seeds-01.png),url(../../assets/img/Leaves-02.png);
  background-repeat: no-repeat,no-repeat,no-repeat,no-repeat;
  background-position: 10% 10%,85% 5%,15% 90%,90% 90%
}

.t-bg-seeds {
  background-color: #fff;
  background-image: url(../../assets/img/Seeds-01.png),url(../../assets/img/Seeds-02.png);
  background-repeat: no-repeat,no-repeat;
  background-position: 10% 10%,90% 80%
}

.t-bg-leaves-green {
  background-color: #a7c5ad;
  background-image: url(../../assets/img/Leaves-01.png),url(../../assets/img/Seeds-02.png),url(../../assets/img/Seeds-01.png),url(../../assets/img/Leaves-02.png);
  background-repeat: no-repeat,no-repeat,no-repeat,no-repeat;
  background-position: 10% 10%,85% 5%,15% 90%,90% 90%
}

.t-bg-seeds-green {
  background-color: #a7c5ad;
  background-image: url(../../assets/img/Seeds-01.png),url(../../assets/img/Seeds-02.png);
  background-repeat: no-repeat,no-repeat;
  background-position: 10% 10%,90% 80%
}

/* #Mega Menu Styles
 –––––––––––––––––––––––––––––––––––––––––––––––––– */
.mega-menu {
  display: none;
  left: 0;
  position: absolute;
  text-align: left;
  width: 100%;
}



/* #hoverable Class Styles
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.hoverable {
  position: static;
}

/*.hoverable > a:after {
  content: "\25BC";
  font-size: 10px;
  padding-left: 6px;
  position: relative;
  top: -1px;
}*/

.hoverable:hover .mega-menu {
  display: block;
}

.submit button {
  @apply text-green tracking-wide bg-white uppercase font-semibold bg-center bg-no-repeat text-center transition duration-300 ease-in-out;
  background-image: url(../../assets/img/button-bg.svg);
  width: 177px;
  height: 50px;

}

.submit button:hover {
  @apply text-white bg-orange
}

.stroke-button {
  @apply  inline-block pt-3 items-center text-green tracking-wide bg-white uppercase font-semibold bg-center bg-no-repeat text-center transition duration-300 ease-in-out;
  background-image: url(../../assets/img/button-bg.svg);
  width: 177px;
  height: 50px;
  &.stroke-button--reverse {
    background-image: url(../../assets/img/button-bg-green.svg);

  }
  &.stroke-button--purple {
    @apply bg-purple text-white;
    background-image: url(../../assets/img/button-bg-white.svg);
    &:hover {
      @apply text-white bg-orange
    }
  }

}

.stroke-button:hover {
  @apply text-white bg-purple
}

#mobileMenu:not(.mm-menu--opened):not(.mm) {
  display: none;
}

/*#mobileMenu {
  display: none;
}*/

.mm-wrapper--opened #header a[href="#mobileMenu"] {
  display: none;
}
#header a[href="#page"] {
  display: none;
}
.mm-wrapper--opened #header a[href="#page"] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.mm-wrapper--opened .mm-page {
  box-shadow: 0 0 10px rgb( 0 0 0 / 20%);
}

.mm-wrapper__blocker {
  background: none;
}

/*.mm-navbar {
  --mm-color-background: var(#998BC0);
  --mm-color-text-dimmed: #fff;
  --mm-color-icon: #fff;
  border-bottom: unset;
  font-size: 18px;
}*/

.mm-panels {
  --mm-color-background: #998BC0;
  --mm-color-text: #ffffff;
  //--mm-color-icon: var(--color-bronze);
}

.mm-panels > .mm-panel {
  //padding-top: 20px;
}
.mm-listitem:after {
  content: none;
}

.mm-listitem > a,
.mm-listitem > span {
  padding: 15px 10px 15px 35px;
}

.responsive-embed {
  iframe {
    width:100%
  }
}



ol.numbered-list {
  counter-reset: li;
  list-style-type: none;
  font-size: 14px;
  line-height: 18px;


  li {
    position: relative;
    padding: 5px 0 5px 30px;

    &:before {
      @apply bg-purple text-white border-none font-body text-xs leading-5;
      content: counter(li);
      counter-increment: li;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      text-align: center;
      position: absolute;
      left: 0;
      top: 7px;
    }
  }
}
