@import 'tailwindcss/utilities';
.bleed-bg {
  -o-border-image: linear-gradient(var(--tw-full-bleed-color) 0 0) 0/ /0 100vw 0 100vw;
  border-image: linear-gradient(var(--tw-full-bleed-color) 0 0) fill 0/ /0 100vw 0 100vw;
}

.bleed-bg-r {
  -o-border-image: linear-gradient(var(--tw-full-bleed-color) 0 0) 0/ /0 100vw 0 0;
  border-image: linear-gradient(var(--tw-full-bleed-color) 0 0) fill 0/ /0 100vw 0 0;
}

.bleed-bg-l {
  -o-border-image: linear-gradient(var(--tw-full-bleed-color) 0 0) 0/ /0 0 0 100vw;
  border-image: linear-gradient(var(--tw-full-bleed-color) 0 0) fill 0/ /0 0 0 100vw;
}

.bleed-gray-light {
  --tw-full-bleed-color: #f8f8f8;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-number-input input:focus {
  outline: none !important;
}

.custom-number-input button:focus {
  outline: none !important;
}

.site-logo {
 /*   width: rem-calc(160);
    height: rem-calc(160);*/
    //top: -2rem;
    left: calc(50% - 4rem);
    //position: absolute;
    //display: flex;
   /* flex-direction: column;
    justify-content: flex-end;
    align-items: center;*/

}

.e-number {
  //background-color: #F3F4F6;
  //padding: 5px;
  //margin-bottom: rem-calc(15);
  //box-shadow: none !important;

  span {
    cursor: pointer;

    &.e-minus,
    &.e-plus {
      //font-size: rem-calc(28);
      //margin: 0;
      //color: #ff0000;
      //width: rem-calc(26);
      //height: rem-calc(30);
      //background-color: #F3F4F6;
      //padding: 4px 3px;
      //display: inline-block;
      //vertical-align: middle;
      //text-align: center;

    }
  }

  input {
    &.e-count {
      margin: 0;
      //background-color: #F3F4F6;
      /*width: calc(100% - 60px);
      height: rem-calc(30);
      text-align: center;
      font-size: 16px;
      display: inline-block;
      vertical-align: middle;*/

    }
  }
}

.error-msg[hidden] {
  opacity: 0;
  height: 0px;
  transform: scale(0);
}
.error-msg {
  font-size: 0.8rem;
  font-weight: 700;
  color: darkred;
  transform-origin: left;
  transition: all 200ms;
  display: block;
}

img.lazy {
  opacity: 0;
}
img:not(.initial) {
  transition: opacity 1s;
}
img.initial,
img.loaded,
img.error {
  opacity: 1;
}

img:not([src]) {
  visibility: hidden;
}

.c-floated-image {
  margin-top: 0;
  margin-bottom: 2.438rem;
  width: 50%;

  &--left {

      margin-left: 0;
      margin-right: 2.438rem;
      float: left;

  }
  &--right {

      margin-right: 0.625rem;
      margin-left: 2.438rem;
      float: right;

  }
  /*@include breakpoint(medium up) {
    width: 50%;
  }*/
}


