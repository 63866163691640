@import 'tailwindcss/base';


h1, .h1 {
  @apply pt-3 tracking-wide font-heading font-bold text-3xl text-purple  md:text-4xl lg:text-5xl;
}

h2, .h2 {
  @apply pt-3 font-heading font-bold text-2xl text-purple  md:text-3xl lg:text-4xl;
}

h3, .h3 {
  @apply pt-3 font-heading font-bold text-xl text-purple  md:text-2xl lg:text-3xl;
}

h4, .h4 {
  @apply pt-3 font-heading font-bold text-lg text-purple  md:text-xl lg:text-2xl;
}

h5, .h5{
  @apply pt-3 font-heading font-bold text-base text-purple md:text-lg lg:text-xl;
}

h1.fancy_title span {
  &:nth-last-of-type(1) {
    @apply text-purple;
  }
}