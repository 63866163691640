
.c-content-quote--wrapper {
  background: transparent;
  position: relative;
  border-top: solid 1px rgba(#474648,0.3);
  border-bottom: solid 1px rgba(#474648,0.3);
  @apply px-[1.5625em] pt-[2.6875em] pb-[1.5625em] md:px-[7.8125em] md:pt-[3.3125em] md:pb-[2.5em];
  blockquote {
    border: none;
  }
  cite {
    color: #474648;
    @apply mt-[0.9375em] text-base;
    font-style: normal;
  }

  &:before {
    position: absolute;
    content: '';
    @apply w-[0.9375em] h-[0.625em] top-[0.875em] left-[0.25em] md:w-[3.6875em] md:h-[2.5625em] md:top-[3.3125em] md:left-[0.9375em];
    background: url("/assets/img/quote-left.svg");
    background-size: cover;
  }
  &:after {
    position: absolute;
    content: '';
    @apply w-[0.9375em] h-[0.625em] top-[0.875em] right-[0.25em] md:w-[3.6875em] md:h-[2.5625em] md:top-[3.3125em] md:right-[0.9375em];
    background: url("/assets/img/quote-right.svg");
    background-size: cover;
  }
}
.c-content-quote--text {
  @apply text-xl;
  color: #474648 !important;

  h2 {
    strong{
      font-family :"Lato", sans-serif!important;
    }
  }
}
.c-content-quote--quote {
  @apply text-5xl mb-[1.875em];
  text-align: center;

  color: #998BC0 !important;
}
.c-content-quote--inner {
  @apply py-[1.25em];
  background: #ffffff;
  color: #F4C194;
  display: flex;
  flex-direction: row;
}