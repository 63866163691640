@charset "UTF-8";
@import "mmenu-js/dist/mmenu.css";
@import '../../node_modules/@splidejs/splide/dist/css/splide.min.css';
@tailwind base;
h1, .h1 {
  @apply pt-3 tracking-wide font-heading font-bold text-3xl text-purple  md:text-4xl lg:text-5xl;
}

h2, .h2 {
  @apply pt-3 font-heading font-bold text-2xl text-purple  md:text-3xl lg:text-4xl;
}

h3, .h3 {
  @apply pt-3 font-heading font-bold text-xl text-purple  md:text-2xl lg:text-3xl;
}

h4, .h4 {
  @apply pt-3 font-heading font-bold text-lg text-purple  md:text-xl lg:text-2xl;
}

h5, .h5 {
  @apply pt-3 font-heading font-bold text-base text-purple md:text-lg lg:text-xl;
}

h1.fancy_title span:nth-last-of-type(1) {
  @apply text-purple;
}

@tailwind components;
.c-content-quote--wrapper {
  background: transparent;
  position: relative;
  border-top: solid 1px rgba(71, 70, 72, 0.3);
  border-bottom: solid 1px rgba(71, 70, 72, 0.3);
  @apply px-[1.5625em] pt-[2.6875em] pb-[1.5625em] md:px-[7.8125em] md:pt-[3.3125em] md:pb-[2.5em];
}
.c-content-quote--wrapper blockquote {
  border: none;
}
.c-content-quote--wrapper cite {
  color: #474648;
  @apply mt-[0.9375em] text-base;
  font-style: normal;
}
.c-content-quote--wrapper:before {
  position: absolute;
  content: "";
  @apply w-[0.9375em] h-[0.625em] top-[0.875em] left-[0.25em] md:w-[3.6875em] md:h-[2.5625em] md:top-[3.3125em] md:left-[0.9375em];
  background: url("/assets/img/quote-left.svg");
  background-size: cover;
}
.c-content-quote--wrapper:after {
  position: absolute;
  content: "";
  @apply w-[0.9375em] h-[0.625em] top-[0.875em] right-[0.25em] md:w-[3.6875em] md:h-[2.5625em] md:top-[3.3125em] md:right-[0.9375em];
  background: url("/assets/img/quote-right.svg");
  background-size: cover;
}

.c-content-quote--text {
  @apply text-xl;
  color: #474648 !important;
}
.c-content-quote--text h2 strong {
  font-family: "Lato", sans-serif !important;
}

.c-content-quote--quote {
  @apply text-5xl mb-[1.875em];
  text-align: center;
  color: #998BC0 !important;
}

.c-content-quote--inner {
  @apply py-[1.25em];
  background: #ffffff;
  color: #F4C194;
  display: flex;
  flex-direction: row;
}

.splide__track--nav > .splide__list > .splide__slide {
  border: 3px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.7;
}

.splide__track--nav > .splide__list > .splide__slide.is-active {
  border: 3px solid #A7C5AD;
  opacity: 1;
}

.splide__arrows--ttb .splide__arrow {
  left: 50%;
  transform: translate(-50%);
}

.splide__arrows--ttb .splide__arrow--prev {
  top: 1em;
}

.splide__arrows--ttb .splide__arrow--prev svg {
  transform: rotate(-90deg);
}

.splide__arrows--ttb .splide__arrow--next {
  bottom: 1em;
  top: auto;
}

.splide__arrows--ttb .splide__arrow--next svg {
  transform: rotate(90deg);
}

.splide__pagination--ttb {
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  left: auto;
  padding: 1em 0;
  right: 1em;
  top: 0;
}

.splide__pagination--ttb .splide__pagination__page {
  height: 20px;
  width: 5px;
}

.splide__arrow {
  -ms-flex-align: center;
  align-items: center;
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 2.5em;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2.5em;
  z-index: 1;
}

.splide__arrow svg {
  fill: #A7C5AD;
  height: 2.5em;
  transition: fill 0.2s linear;
  width: 2.5em;
}

.splide__arrow:hover:not(:disabled) svg {
  fill: #57e1d9;
}

.splide__arrow:disabled {
  opacity: 0.3;
}

.splide__arrow:focus-visible {
  outline: 3px solid #A7C5AD;
  outline-offset: 3px;
}

.splide__arrow--prev {
  left: 1em;
}

.splide__arrow--prev svg {
  transform: scaleX(-1);
}

.splide__arrow--next {
  right: 1em;
}

.splide.is-focus-in .splide__arrow:focus {
  outline: 3px solid #A7C5AD;
  outline-offset: 3px;
}

.splide__pagination {
  bottom: 1em;
  left: 0;
  padding: 0 1em;
  position: absolute;
  right: 0;
  z-index: 1;
}

.splide__pagination__page {
  background: #ccc;
  border: 0;
  border-radius: 2.5px;
  display: inline-block;
  height: 5px;
  margin: 3px;
  padding: 0;
  position: relative;
  transition: background-color 0.2s linear;
  width: 20px;
}

.splide__pagination__page.is-active {
  background: #A7C5AD;
  z-index: 1;
}

.splide__pagination__page:hover {
  background: #57e1d9;
  cursor: pointer;
  opacity: 0.9;
}

.splide__pagination__page:focus-visible {
  outline: 3px solid #A7C5AD;
  outline-offset: 3px;
}

.splide.is-focus-in .splide__pagination__page:focus {
  outline: 3px solid #A7C5AD;
  outline-offset: 3px;
}

.splide__slide {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-radius: 4px;
}

.splide__slide:focus {
  outline: 0;
}

@supports (outline-offset: -3px) {
  .splide__slide:focus-visible {
    outline: 3px solid #A7C5AD;
    outline-offset: -3px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide__slide:focus-visible {
    border: 3px solid #A7C5AD;
  }
}
@supports (outline-offset: -3px) {
  .splide.is-focus-in .splide__slide:focus {
    outline: 3px solid #A7C5AD;
    outline-offset: -3px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide.is-focus-in .splide__slide:focus {
    border: 3px solid #A7C5AD;
  }
  .splide.is-focus-in .splide__track > .splide__list > .splide__slide:focus {
    border-color: #A7C5AD;
  }
}
.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  @apply space-x-2 mb-2;
}
.splide__pagination .splide__pagination__page {
  @apply h-4 w-4 relative bg-purple opacity-20 rounded-full;
}
.splide__pagination .splide__pagination__page.is-active {
  @apply bg-purple opacity-100 scale-100;
}
.splide__pagination.white__pagination {
  @apply space-x-2 mb-8;
}
.splide__pagination.white__pagination .splide__pagination__page {
  @apply h-3 w-3 relative bg-white opacity-20 rounded-full;
}
.splide__pagination.white__pagination .splide__pagination__page.is-active {
  @apply bg-white opacity-100 scale-100;
}
.splide__pagination .splide__pagination__page {
  position: relative;
}

/*.splide__pagination {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  pointer-events: none
}

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto
}*/
.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  position: relative;
  visibility: hidden;
}

.splide.is-initialized,
.splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  position: relative;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  animation: splide-loading 1s linear infinite;
  border: 2px solid #A7C5AD;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.splide__sr {
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.splide__toggle.is-active .splide__toggle__play,
.splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
.splide__track--draggable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  margin: 0 !important;
  opacity: 0;
  z-index: 0;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__progress__bar {
  background: #ccc;
  height: 3px;
}

.splide__toggle {
  cursor: pointer;
}

.splide__toggle:focus-visible {
  outline: 3px solid #A7C5AD;
  outline-offset: 3px;
}

.splide.is-focus-in .splide__toggle:focus {
  outline: 3px solid #A7C5AD;
  outline-offset: 3px;
}

.splide__arrows--rtl .splide__arrow--prev {
  left: auto;
  right: 1em;
}

.splide__arrows--rtl .splide__arrow--prev svg {
  transform: scaleX(1);
}

.splide__arrows--rtl .splide__arrow--next {
  left: 1em;
  right: auto;
}

.splide__arrows--rtl .splide__arrow--next svg {
  transform: scaleX(-1);
}

.splide__slider > .splide__arrows .splide__arrow--prev {
  left: -2.5em;
}

.splide__slider > .splide__arrows .splide__arrow--next {
  right: -2.5em;
}

.carousel-splide-products {
  @apply md:px-24 my-10;
}

.t-bg-leaves {
  background-color: #fff;
  background-image: url(../../assets/img/Leaves-01.png), url(../../assets/img/Seeds-02.png), url(../../assets/img/Seeds-01.png), url(../../assets/img/Leaves-02.png);
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-position: 10% 10%, 85% 5%, 15% 90%, 90% 90%;
}

.t-bg-seeds {
  background-color: #fff;
  background-image: url(../../assets/img/Seeds-01.png), url(../../assets/img/Seeds-02.png);
  background-repeat: no-repeat, no-repeat;
  background-position: 10% 10%, 90% 80%;
}

.t-bg-leaves-green {
  background-color: #a7c5ad;
  background-image: url(../../assets/img/Leaves-01.png), url(../../assets/img/Seeds-02.png), url(../../assets/img/Seeds-01.png), url(../../assets/img/Leaves-02.png);
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-position: 10% 10%, 85% 5%, 15% 90%, 90% 90%;
}

.t-bg-seeds-green {
  background-color: #a7c5ad;
  background-image: url(../../assets/img/Seeds-01.png), url(../../assets/img/Seeds-02.png);
  background-repeat: no-repeat, no-repeat;
  background-position: 10% 10%, 90% 80%;
}

/* #Mega Menu Styles
 –––––––––––––––––––––––––––––––––––––––––––––––––– */
.mega-menu {
  display: none;
  left: 0;
  position: absolute;
  text-align: left;
  width: 100%;
}

/* #hoverable Class Styles
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.hoverable {
  position: static;
}

/*.hoverable > a:after {
  content: "\25BC";
  font-size: 10px;
  padding-left: 6px;
  position: relative;
  top: -1px;
}*/
.hoverable:hover .mega-menu {
  display: block;
}

.submit button {
  @apply text-green tracking-wide bg-white uppercase font-semibold bg-center bg-no-repeat text-center transition duration-300 ease-in-out;
  background-image: url(../../assets/img/button-bg.svg);
  width: 177px;
  height: 50px;
}

.submit button:hover {
  @apply text-white bg-orange;
}

.stroke-button {
  @apply inline-block pt-3 items-center text-green tracking-wide bg-white uppercase font-semibold bg-center bg-no-repeat text-center transition duration-300 ease-in-out;
  background-image: url(../../assets/img/button-bg.svg);
  width: 177px;
  height: 50px;
}
.stroke-button.stroke-button--reverse {
  background-image: url(../../assets/img/button-bg-green.svg);
}
.stroke-button.stroke-button--purple {
  @apply bg-purple text-white;
  background-image: url(../../assets/img/button-bg-white.svg);
}
.stroke-button.stroke-button--purple:hover {
  @apply text-white bg-orange;
}

.stroke-button:hover {
  @apply text-white bg-purple;
}

#mobileMenu:not(.mm-menu--opened):not(.mm) {
  display: none;
}

/*#mobileMenu {
  display: none;
}*/
.mm-wrapper--opened #header a[href="#mobileMenu"] {
  display: none;
}

#header a[href="#page"] {
  display: none;
}

.mm-wrapper--opened #header a[href="#page"] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.mm-wrapper--opened .mm-page {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.mm-wrapper__blocker {
  background: none;
}

/*.mm-navbar {
  --mm-color-background: var(#998BC0);
  --mm-color-text-dimmed: #fff;
  --mm-color-icon: #fff;
  border-bottom: unset;
  font-size: 18px;
}*/
.mm-panels {
  --mm-color-background: #998BC0;
  --mm-color-text: #ffffff;
}

.mm-listitem:after {
  content: none;
}

.mm-listitem > a,
.mm-listitem > span {
  padding: 15px 10px 15px 35px;
}

.responsive-embed iframe {
  width: 100%;
}

ol.numbered-list {
  counter-reset: li;
  list-style-type: none;
  font-size: 14px;
  line-height: 18px;
}
ol.numbered-list li {
  position: relative;
  padding: 5px 0 5px 30px;
}
ol.numbered-list li:before {
  @apply bg-purple text-white border-none font-body text-xs leading-5;
  content: counter(li);
  counter-increment: li;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  left: 0;
  top: 7px;
}

@tailwind utilities;
.bleed-bg {
  -o-border-image: linear-gradient(var(--tw-full-bleed-color) 0 0) 0//0 100vw 0 100vw;
  border-image: linear-gradient(var(--tw-full-bleed-color) 0 0) fill 0//0 100vw 0 100vw;
}

.bleed-bg-r {
  -o-border-image: linear-gradient(var(--tw-full-bleed-color) 0 0) 0//0 100vw 0 0;
  border-image: linear-gradient(var(--tw-full-bleed-color) 0 0) fill 0//0 100vw 0 0;
}

.bleed-bg-l {
  -o-border-image: linear-gradient(var(--tw-full-bleed-color) 0 0) 0//0 0 0 100vw;
  border-image: linear-gradient(var(--tw-full-bleed-color) 0 0) fill 0//0 0 0 100vw;
}

.bleed-gray-light {
  --tw-full-bleed-color: #f8f8f8;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-number-input input:focus {
  outline: none !important;
}

.custom-number-input button:focus {
  outline: none !important;
}

.site-logo {
  /*   width: rem-calc(160);
     height: rem-calc(160);*/
  left: calc(50% - 4rem);
  /* flex-direction: column;
   justify-content: flex-end;
   align-items: center;*/
}

.e-number span {
  cursor: pointer;
}
.e-number input.e-count {
  margin: 0;
  /*width: calc(100% - 60px);
  height: rem-calc(30);
  text-align: center;
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;*/
}

.error-msg[hidden] {
  opacity: 0;
  height: 0px;
  transform: scale(0);
}

.error-msg {
  font-size: 0.8rem;
  font-weight: 700;
  color: darkred;
  transform-origin: left;
  transition: all 200ms;
  display: block;
}

img.lazy {
  opacity: 0;
}

img:not(.initial) {
  transition: opacity 1s;
}

img.initial,
img.loaded,
img.error {
  opacity: 1;
}

img:not([src]) {
  visibility: hidden;
}

.c-floated-image {
  margin-top: 0;
  margin-bottom: 2.438rem;
  width: 50%;
  /*@include breakpoint(medium up) {
    width: 50%;
  }*/
}
.c-floated-image--left {
  margin-left: 0;
  margin-right: 2.438rem;
  float: left;
}
.c-floated-image--right {
  margin-right: 0.625rem;
  margin-left: 2.438rem;
  float: right;
}